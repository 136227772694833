import React from 'react';
import {graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';

import {decodeHTML} from 'assets/helper.js';
import Graphic from 'components/Graphic.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tile from 'components/Tile.js';
import i18n from 'data/i18n.en.json';


export default class BlogCategory extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const info = this.props.data.categoryInfo;
        return (
            <Layout className="blog">
                <Meta
                    description={info.description}
                    title={info.name}
                />
                <section className="grid">
                    <article>
                        <Link to={this.props.data.blogPage.path}>
                            <Graphic name="iconBlog"/>
                            {i18n.blog.allPosts}
                        </Link>
                        <h1>{info.name}</h1>
                        <p>{info.description}</p>
                    </article>
                    {this.props.data.categoryPost.edges.map(({node}) =>
                        <Tile
                            copy={node.acf && node.acf.excerpt}
                            image={node.master_image}
                            isVideo={
                                node.categories.some((cat) =>
                                    cat.wordpress_id === 1149)
                            }
                            key={node.wordpress_id}
                            title={decodeHTML(node.title)}
                            to={node.path}
                        />
                    )}
                </section>
            </Layout>
        );
    }
}

export const query = graphql`
    query($id: Int!) {
        blogPage: wordpressPage(wordpress_id: {eq: 53}) {
            path
        }
        categoryInfo: wordpressCategory(wordpress_id: {eq: $id}) {
            description
            name
        }
        categoryPost: allWordpressPost(
            filter: {
                categories: {
                    elemMatch: {
                        wordpress_id: {eq: $id},
                    }
                }
            }
            sort: {fields: date, order: DESC}
        ) {
            edges {
                node {
                    acf {excerpt}
                    categories {
                        description
                        slug
                        wordpress_id
                    }
                    master_image: jetpack_featured_media_url
                    path
                    title
                    wordpress_id
                }
            }
        }
    }
`;
